/* .date-menu .usermenu{
    width: 600px;
    overflow-x: scroll;
} */

.date-menu {
    position: absolute;
    width: 50%;
    top: 70px;
    right: 1%;
    z-index: 999999;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
}

.date-menu .usermenu {
    width: 100%;
}

.hide-date-menu {
    display: none;
}

.date-menu .usermenu-button {
    width: 96%;
    display: flex;
    justify-content: flex-end;
    padding: 0 0 20px 0;
}

.date-menu .usermenu-button button {
    background-color: #c71661;
    color: white;
    width: 100px;
}

.rdrDateRangePickerWrapper {
    display: block !important;
}

.rdrDateRangePickerWrapper,
.rdrDateRangePickerWrapper {
    width: 100% !important;
}

.rdrDefinedRangesWrapper {
    padding: 20px 20px 0px 20px !important;
}

.rdrDefinedRangesWrapper {
    width: 100% !important;
}

.rdrCalendarWrapper {
    width: 100%;
}

.rdrStaticRanges {
    flex-direction: row !important;
    justify-content: space-around;
    gap: 15px;
}

.rdrInputRanges {
    display: flex;
    justify-content: center;
}

.rdrMonth {
    width: 50% !important;
}

.rdrStaticRangeLabel {
    padding: 0px !important;
}

@media only screen and (max-width:1320px) {

    .date-menu {
        width: 65%;
    }
}

@media only screen and (max-width:1024px) {

    .date-menu {
        width: 75%;
    }
}

@media only screen and (max-width:900px) {

    .date-menu {
        width: 100%;
    }
}

@media only screen and (max-width:730px) {

    .date-menu {
        width: 110%;
        right: -5%;
    }
}