.login-screen {
    background-color: #f4d8e6;
    height: 100vh;
    padding: 100px 0px;
}

.login-screen-content {
    height: 100%;
    display: flex;
    gap: 50px;
    justify-content: space-between;
}

.login-images {
    width: 65%;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.login-image-logo {
    max-width: 253px;
    height: 270px;
    margin: 0 auto;
}

.login-image-pattern {
    max-width: 900px;
}

.login-image-logo img,
.login-image-pattern img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.login-form-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5%;
}

.login-form {
    background-color: white;
    width: 400px;
    border-radius: 10px;
    box-shadow: 1px 2px 4px 2px rgba(0, 0, 0, 0.25);
    padding: 80px 20px 120px 20px;
}

.login-form-title {
    text-align: center;
}

.login-form-title h2 {
    text-transform: capitalize;
    font-size: 30px;
    font-weight: 700;
}

.login-form-inputs .input {
    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.login-form-inputs input {
    padding: 10px 14px;
    border-radius: 10px;
    /* box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.25); */
    font-weight: 600;
    color: #6E7881;
}

.login-forgot-password {
    margin: 30px 0px;
    cursor: pointer;
}

.login-forgot-password h6 {
    font-size: 15px;
    font-weight: 600;
    text-decoration-line: underline;
    text-align: end;
}

.login-button {
    border-radius: 10px;
    background: #C71661;
    color: white;
    padding: 7px 50px;
    box-shadow: 1px 2px 4px 2px rgba(0, 0, 0, 0.25);
}


@media only screen and (max-width:991px) {
    .login-image-logo {
        max-width: 200px;
        height: 250px;
    }

    .login-form {
        width: 350px;
        border-radius: 10px;
        box-shadow: 1px 2px 4px 2px rgba(0, 0, 0, 0.25);
        padding: 80px 20px 120px 20px;
    }
}