@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=Syne:wght@400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
}

body {
  background: #fcf3f7;
}

.spinner {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner .path {
  animation: infinity-load 1.5s infinite cubic-bezier(0.65, 0.05, 0.36, 1);
}

@keyframes infinity-load {
  to {
    stroke-dashoffset: 10;
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.loader-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  /* background: rgba(0, 0, 0, 0.4); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-area,
.select-area,
.date-picker-control div {
  border-radius: 10px !important;
}

.daily-option-form .input-area {
  border-radius: 50px !important;
}

.date-picker-control {
  width: 100% !important;
}

/* .main-container {
  display: flex;
  overflow: hidden;
}

.main-content {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  padding: 50px 30px 40px 30px;
  transition: all 0.3s ease-in-out;
} */

.main-container {
  display: flex;
  position: relative;
  justify-content: flex-start;
}

.rightSide-component {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  transition: all 0.3s linear;
}

/* .hide-sidebar .rightSide-component {
  width: calc(100% - 70px);
} */

.main-content {
  background-color: #e9ebed;
  min-height: 100vh;
  padding: 20px;
}

.white-box {
  background-color: white;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 20px;
  width: 100%;
}

.white-box .table th,
.white-box .table td {
  border: none;
}

.white-box .table th {
  font-size: 18px;
}

.white-box .table td {
  color: #79787c;
  font-size: 16px;
}

.btn-pink {
  background-color: #c71661;
  color: white;
}

.btn-pink:hover {
  background-color: #c71661;
  color: white;
}

.upload-lable {
  width: 100%;
}

.upload-lable p {
  margin-bottom: 0px !important;
}

.file-upload-icon {
  width: 80%;
  margin: 0 auto;
  padding: 10px 0px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c71661;
}

.file-upload-icon img {
  width: 20%;
  object-fit: contain;
}

.close-btn {
  font-size: 25px;
  margin-bottom: 5px;
  cursor: pointer;
}

.btn:hover,
.btn:focus {
  background: #c71661;
  color: white;
}

.date-range-btn:focus {
  background-color: white;
  color: #79787c;
}

.form-upload-img {
  width: 100px;
  height: 100px;
  margin: 20px auto;
}

.form-upload-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.cursor-pointer {
  cursor: pointer;
}

.error-red-border {
  border-color: #d32f2f !important;
}

.setting-section .tab-pane {
  width: 100%;
  margin: 0 auto;
}

.setting-section .input-field-area input {
  padding: 12px 10px;
}

.daily-form-data .setting-form {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
}

.input-field {
  flex-basis: 500px;
  flex-grow: 1;
}
.input-field-textarea {
  flex-basis: 100%;
  flex-grow: 1;
}

::-webkit-scrollbar {
  width: 0px !important;
  height: 8px !important;
}

@media only screen and (max-width: 1100px) {
  .table {
    width: 750px !important;
  }

  .table-container-width {
    overflow-x: scroll !important;
  }

  .table-container-width::-webkit-scrollbar {
    height: 10px;
  }

  .table-container-width::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  .table-container-width::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 10px;
  }
}
