.dashboard {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: nowrap;
}

.live-users-overview {
  width: 50%;
}

.analytics-graph-main {
  margin-top: 50px;
}

.analytics-graph-main .analytics-graph {
  margin-top: 20px;
  width: 35%;
  position: relative;
}

.analytics-graph-data {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.analytics-graph-data h3 {
  font-weight: 600;
  color: #c71661;
}

.analytics-overview {
  width: 50%;
}

.analytics-overview-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.ip-btn {
  margin-right: 15px;
  background-color: #c71661;
  color: white;
}

.dashboard-cards {
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  flex-wrap: wrap;
}

.card {
  /* width: 30%; */
  flex-basis: 170px;
  flex-grow: 1;
  padding: 40px 0;
  border-radius: 50px;
  border: 2px solid #c71661;
  background: #000;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.card h2 {
  color: #c71661;
  text-align: center;
  font-weight: 700;
  letter-spacing: -0.3px;
}

.card h3 {
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 128%;
  letter-spacing: -0.5px;
}

.hope-overview-cards {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.spinner-card-main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  color: #848b91;
  width: 48%;
}

.spinner-card-main .spinner-card {
  width: 25%;
}

.spinner-card-main .spinner-card-details {
  width: 75%;
}

.spinner-card-main .spinner-card-details h2 {
  font-weight: 600;
  letter-spacing: -1px;
}

.spinner-arrow-rotate {
  transform: rotate(180deg);
}
