.TableFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 15px;
}

.TableFooter .rowsPerPage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.TableFooter nav {
    margin-bottom: 10px;
}

.TableFooter nav ul li button.Mui-selected {
    background-color: #c71661;
    color: white;
}

.TableFooter nav ul li button.Mui-selected:hover {
    background-color: #c71661;
    color: white;
}

.TableFooter .rowsPerPage .label {
    font-size: 15px;
    line-height: 18px;
    white-space: nowrap;
    color: #A3A3A3;
}

.TableFooter .rowsPerPage .border-less-control .footer-dropdown div {
    padding: 4px 28px 4px 10px;
    font-size: 15px;
    line-height: 26px;
    color: #3E3E3E;
}

.TableFooter .rowsPerPage .border-less-control .footer-dropdown svg {
    border-color: #3E3E3E;
}

.TableFooter .rowsPerPage .border-less-control .footer-dropdown fieldset {
    display: none;
}

@media only screen and (max-width:800px) {

    .TableFooter {
        flex-direction: column;
    }

}