.add-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.add-button .input-field-area {
    width: 70%;
}

.add-button .add-options-btn {
    width: 27%;
    height: 55px;
    margin: 0;
    margin-top: 28px;
}

.ticket-benefits-form {
    top: 310px;
}

.ticket-form-data td {
    padding: 5px;
    border-bottom: none;
}

.Mui-checked {
    color: #c71661 !important;
}

.Mui-checked+.MuiSwitch-track {
    background: #c71661 !important;
}
