.user-table-title h2 {
  /* font-weight: 700; */
  font-size: 30px;
}

.user-table-title h2 span {
  font-size: 24px;
  font-weight: 400;
}

.date-range-btn {
  border: 1px solid black;
  border-radius: 10px;
  color: #6e7881;
  font-weight: 600;
}

.date-range-btn:hover {
  border: 1px solid black;
  border-radius: 10px;
  color: #6e7881;
  font-weight: 600;
  background-color: white;
}

.icon-panel {
  display: flex !important;
  justify-content: center;
  gap: 15px;
}

.ticket-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: #c71661;
  color: white;
}

.edit-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: #c71661;
  color: white;
}

.edit-icon-image {
  margin-left: 3px;
}

.delete-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: #c71661;
  color: white;
  font-size: 18px;
}

.delete-all-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 30px; */
  padding: 0px 10px;
  height: 30px;
  border-radius: 5px;
  background-color: #c71661;
  color: white;
  font-size: 18px;
}

.delete-all-icon span {
  font-family: syne !important;
  text-transform: capitalize;
  font-size: 14px;
  margin-left: 5px;
  cursor: pointer;
}

.form-check-input {
  border-color: rgb(199, 22, 97);
}

.form-check-input:checked {
  background-color: #c71661;
  border-color: #c71661;
}

.form-check-input:focus {
  border-color: rgb(199, 22, 97);
  box-shadow: none;
}

.delete-th {
  width: 130px;
}

.login-logout-details-table th{
  border-bottom: none;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;   
}

.login-logout-details-table td {
  border-bottom: none;
  text-align: center;
}
