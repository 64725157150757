.sidebar-main {
  width: 300px;
  height: 100vh !important;
  background: #c71661;
  overflow-y: scroll;
  transition: all 0.3s ease-in-out;
}

.hide-sidebar .sidebar-main {
  width: 0;
}

.hide-sidebar .side-content {
  display: none;
}

.side-header {
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  gap: 10px;
}

.sidebar-image-2 img,
.sidebar-image-1 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sidebar-image-1 {
  width: 80px;
  height: 70px;
}

.sidebar-image-2 {
  width: 130px;
  height: 60px;
  margin-top: 7px;
}

.sidebar-main hr {
  color: rgba(255, 255, 255);
  border-top: 4px solid;
  width: 90%;
  margin: 20px auto;
}

.side-content {
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 30px;
}

.sidebar-main ul {
  padding-left: 1.5rem;
}

.main-navigation {
  overflow: auto;
  max-height: 100%;
}

.main-navigation .nav-link {
  display: block;
  padding: 0;
}

.main-navigation .nav-link .nav-item {
  position: relative;
  display: flex;
  text-decoration: none;
  align-items: center;
  padding: 8px 30px;
  color: white;
}

.main-navigation .nav-link .nav-item > span {
  display: inline-block;
  margin-right: 8px;
  font-size: 20px;
  font-weight: 600;
}

.main-navigation .nav-link .nav-item.active {
  background-color: #e9ebed;
  color: black;
  border-radius: 25px 0 0 25px;
}

.logout {
  display: flex;
  justify-content: center;
}

.logout-btn {
  border: 4px solid white;
  color: white;
  width: 180px;
  border-radius: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.logout-btn:hover {
  color: white;
  border: 4px solid white;
}

.logout-icon {
  width: 20px;
}

@media only screen and (max-width: 1230px) {
  .main-navigation .nav-link .nav-item > span {
    font-size: 22px;
  }
}

@media only screen and (max-width: 980px) {
  .sidebar-main ul {
    padding-left: 1rem;
  }

  .main-navigation .nav-link .nav-item > span {
    font-size: 20px;
  }
}

@media only screen and (max-width: 980px) {
  .sidebar-main ul {
    padding-left: 0.8rem;
  }
}
