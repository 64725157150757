.comment-form-main {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.comment-form {
  width: 35%;
}

.comment-form-button {
  width: 50%;
}

.comment-form-button button svg {
  font-size: 30px;
}

.chitchat-from-section .input-field-area textarea {
  height: 150px !important;
}

.comment-form-tab-panel .nav {
  width: 100% !important;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.comment-form-tab-panel .nav-link {
  font-size: 20px;
  text-transform: capitalize;
  text-align: center;
  background-color: #c71661;
  border-radius: 20px;
  color: white;
  font-weight: 600;
}

.comment-form-tab-panel .nav-item {
  width: 80%;
}

.comment-form-tab-panel .nav-item button {
  width: 100%;
  padding: 20px 20px;
  font-size: 24px;
}

.comment-form-tab-panel .nav-item .active {
  background-color: black;
}

.chitchat-from-section .tab-content {
  margin-top: 0px;
}

.add-options-btn {
  width: 150px;
  padding: 15px;
  margin: 0 auto;
}

.add-options-btn p {
  margin-bottom: 0px;
  font-size: 14px;
}

.add-options-btn svg {
  width: 20px;
  height: 20px;
}

.daily-option-form {
  padding: 0px 0px 20px 0px;
  width: 30%;
  margin: 0 auto;
  position: absolute;
  top: 200px;
  right: 10%;
}

.daily-option-form .daily-form-title {
  background-color: #c71661;
  text-align: center;
  border-radius: 20px 20px 0px 0px;
  padding: 10px;
}

.daily-option-form .daily-form-title h4 {
  color: white;
  font-weight: 600;
  margin-bottom: 0px;
}

.daily-option-form .daily-form-data {
  padding: 0px 25px;
}

.daily-option-form input {
  padding: 12px 15px;
}

.option-field {
  position: relative;
}

.field-delete-icon {
  position: absolute;
  font-size: 20px;
  top: 15px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-field-area p,
.input-field-upload p {
  color: red;
  font-size: 13px;
  margin-bottom: 0px;
  margin-left: 10px;
}

.chitchat-box {
  width: 100%;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.chitchat-image {
  max-width: 200px;
  height: 200px;
  flex-grow: 1;
}

.chitchat-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.chitchat-content {
  flex-basis: 500px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.chitchat-counts {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 5px;
}

.chitchat-counts .count-data {
  flex-basis: 32.6%;
  flex-grow: 1;
  font-size: 20px;
  padding: 5px;
  border: 1px solid #e1e5e9;
  border-radius: 6px;
  text-align: center;
}

.chitchat-counts .poll-data {
  flex-basis: 24%;
  flex-grow: 1;
  font-size: 16px;
  padding: 5px;
  border: 1px solid #e1e5e9;
  border-radius: 6px;
  text-align: center;
}

@media only screen and (max-width: 1032px) {
  .chitchat-image {
    max-width: 100%;
  }
}

@media only screen and (max-width: 1366px) {
  .comment-form {
    width: 45%;
  }

  .comment-form-button {
    width: 60%;
  }

  .daily-option-form {
    width: 35%;
  }
}

@media only screen and (max-width: 1152px) {
  .comment-form-tab-panel .nav-item button {
    padding: 15px 20px;
    font-size: 20px;
  }

  .comment-form-button button svg {
    font-size: 25px;
  }

  .comment-form-main {
    gap: 30px;
  }

  .comment-form {
    width: 55%;
  }

  .daily-option-form {
    width: 40%;
    top: 35%;
    right: 5%;
  }

  .comment-form-tab-panel .nav-item {
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .comment-form-main {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    position: relative;
  }

  .comment-form {
    width: 100%;
  }

  .daily-option-form {
    position: initial;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .comment-form-button {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .daily-option-form {
    position: initial;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 30px;
    margin-top: 30px;
  }
}
