.upload-title h2 {
    /* font-weight: 700; */
    font-size: 32px;
}

.upload-title h2 span {
    font-weight: 400;
}

.upload-tab-panel .nav {
    width: 100% !important;
    align-items: center;
    gap: 30px;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.upload-tab-panel .nav-link {
    font-size: 20px;
    text-transform: capitalize;
    text-align: center;
    background-color: #c71661;
    border-radius: 20px;
    color: white;
    /* font-weight: 600; */

}

.detox-green {
    background-color: #05AD52 !important;
}

.upload-tab-panel .nav-item {
    width: 15%;
}

.upload-tab-panel .nav-item button {
    width: 100%;
}

.upload-tab-panel .nav-item .active {
    background-color: black !important;
}


.daily-form {
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    width: 100%;
}

.daily-form-data{
    padding: 0px 15px;
}

.daily-form-title h4 {
    text-transform: capitalize;
    color: #08080D;
    font-weight: 700;
    margin-bottom: 5px;
}

.daily-form-title hr{
    margin: 0;
}

.tab-content{
    margin-top: 25px;
}

.text-box{
    min-height: 200px;
}
 
.input-field-area textarea{
    min-height: 100px !important;
}

#file-upload-button{
    border: 2px solid red;
    height: 200px !important;
}

.input-field-upload{
    text-align: center;
    border: 1px solid #b1b1b1;
    border-radius: 10px;
    padding: 20px 0px;
}

.input-field-upload h6{
    font-size: 14px;
    font-family: syne !important;
    margin-bottom: 10px;
}

.submit-button{
    width: 120px;
    border: 1px solid #c71661;
}
.submit-button:hover{
    border: 1px solid #c71661;
}

.create-daily-btn,
.create-daily-btn:hover {
    background-color: #c71661;
    color: white;
}










@media only screen and (max-width:1920px) {}

@media only screen and (max-width:1680px) {

   
}

@media only screen and (max-width:1540px) {

    .upload-tab-panel .nav-link {
        font-size: 18px;
    
    }

}

@media only screen and (max-width:1440px) {

    .upload-tab-panel .nav {
        gap: 20px;
    }

    .upload-tab-panel .nav-item {
        width: 20%;
    }
  
   
}

@media only screen and (max-width:1375px) {

    .upload-tab-panel .nav-link {
        font-size: 16px;
    
    }

    .upload-tab-panel .nav {
        gap: 15px;
    }

    .upload-tab-panel .nav-item {
        width: 20%;
    }

}

@media only screen and (max-width:1260px) {

    .upload-tab-panel .nav {
        gap: 15px;
        flex-wrap: wrap;
    }

    .upload-tab-panel .nav-item {
        width: 32%;
    }

}

@media only screen and (max-width:1152px) {}

@media only screen and (max-width:1080px) {}

@media only screen and (max-width:1040px) {

    .upload-tab-panel .nav-item {
        width: 31%;
    }
}

@media only screen and (max-width:991px) {}

@media only screen and (max-width:850px) {}

@media only screen and (max-width:767px) {

    .upload-tab-panel .nav-item {
        width: 48%;
    }

}

@media only screen and (max-width:680px) {
    .upload-tab-panel .nav-item {
        width: 45%;
    }
    .upload-tab-panel .nav-link {
        font-size: 14px;
    
    }
}

@media only screen and (max-width:576px) {}

@media only screen and (max-width:380px) {}